<template>
  <b-field v-if="!isHidden" :label="`Software ${required ? '*' : ''}`">
    <b-select
      ref="sofware"
      v-model="field.software"
      :disabled="disabled"
      :loading="loading"
      :required="required"
      placeholder="Select site software…"
      expanded
      @input="$emit('change', field.software)"
    >
      <option v-if="loading" disabled>Loading software options…</option>

      <option v-if="!loading" disabled>Select site software…</option>

      <option
        v-for="option in sortedOptions"
        :key="option._id"
        :value="option._id"
        >{{ option.name }}</option
      >
    </b-select>
  </b-field>
</template>

<script>
import { collection, getDocs, query, where } from "@firebase/firestore";
export default {
  name: "SiteSoftwareField",
  props: {
    software: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      isHidden: false,
      options: {},
      field: {
        software: this.software
      }
    };
  },
  computed: {
    sortedOptions() {
      return this.$_.sortBy(this.options, ["order"]);
    }
  },
  created() {
    this.getSoftware();
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.sofware.$el.querySelector("select").focus();
    }
  },
  methods: {
    getSoftware() {
      try {
        const softwareRef = query(
          collection(this.$firestore, `software`),
          where(`isVisible`, `==`, true)
        );
        getDocs(softwareRef).then(snapshot => {
          this.loading = false;
          if (!snapshot.size) return this.setFallback();
          snapshot.docs.forEach(software => {
            this.$set(
              this.options,
              software.id,
              this.$_.merge({}, software.data(), { _id: software.id })
            );
          });
        });
      } catch (error) {
        console.error(error.message);
        this.setFallback();
      }
    },
    setFallback() {
      this.$emit("change", "other"); // Default
      this.isHidden = true;
    }
  }
};
</script>
